<template lang="pug">
.app(:data-step="step")
  .app-header.bg-light.d-flex.align-items-center.ps-3
    img.app-logo.w-auto(src="@/assets/images/logo.png")

  .container-xxl.pb-3
    .step.step-terms(v-if="step === 1")
      terms
      div(v-waypoint='{ active: true, callback: onWaypoint, options: intersectionOptions }')
    .step.step-terms(v-if="step === 2")
      a.mx-n12.mb-3.d-block(href="#register")
        img.w-100(src=`@/assets/images/gameact.jpg`)

      form#register.d-grid.gap-4.mb-4.pt-3(@submit.prevent="onSubmit", novalidate)
      
        //- label.form-label.fs-5.fw-bold.d-block กรุณากรอกข้อมูลให้ครบถ้วน<br>เพื่อรักษาสิทธิ์ผู้โชคดี และการรับของรางวัล<br>ตามเงื่อนไขของธนาคาร
        .form-group
          label.form-label.fs-5.fw-bold.mb-3(for="first_name") ชื่อ
            small.fs-12.fw-normal.opacity-50.d-block (ใช้เพื่อคัดเลือก และประกาศรางวัล)
          input#first_name.form-control.form-control-lg(
            type="text",
            v-model="form.first_name",
            name="first_name"
            required
          )
          .invalid-feedback กรุณากรอก ชื่อ

        .form-group
          label.form-label.fs-5.fw-bold.mb-3(for="last_name") นามสกุล
            small.fs-12.fw-normal.opacity-50.d-block (ใช้เพื่อคัดเลือก และประกาศรางวัล)
          input#last_name.form-control.form-control-lg(
            type="text",
            v-model="form.last_name",
            required
            name="last_name"
          )
          .invalid-feedback กรุณากรอก นามสกุล

        .form-group
          label.form-label.fs-5.fw-bold.mb-3(for="phone") เบอร์ติดต่อ
            small.fs-12.fw-normal.opacity-50.d-block (ใช้เพื่อคัดเลือก และประกาศรางวัล)
          input#phone.form-control.form-control-lg(
            type="tel",
            v-model="form.phone",
            minlength="9",
            maxlength="10",
            required
            name="phone"
          )
          .invalid-feedback กรุณากรอก เบอร์ติดต่อ

        .form-group
          label.form-label.fs-5.fw-bold.mb-3(for="address") ที่อยู่
            small.fs-12.fw-normal.opacity-50.d-block (ใช้เพื่อคัดเลือก และประกาศรางวัล)

          textarea#address.form-control.form-control-lg(
            v-model="form.address",
            rows="2",
            required
            name="address"
          )
          .invalid-feedback กรุณากรอก ที่อยู่ในการจัดส่ง

        .form-group
          label.form-label.fs-14.mb-3.opacity-50(for="province") จังหวัด
          select#province.form-select.form-select-lg(v-model="address.province" required)
            option(value="" disabled) เลือกจังหวัด
            option(v-for='province in province',
          :value='province.id',
          :key='province.id') {{ province.name_th }}
          .invalid-feedback กรุณาเลือกจังหวัด

        .form-group
          label.form-label.fs-14.mb-3.opacity-50(for='district') เขต (อำเภอ)
          select#district.form-select.form-select-lg(v-model="address.district" required)
            option(value="" disabled) เลือกเขต (อำเภอ)
            option(v-for='district in districts',
          :value='district.id',
          :key='district.id') {{ district.name_th }}
          .invalid-feedback กรุณาเลือกเขต (อำเภอ)

        .form-group
          label.form-label.fs-14.mb-3.opacity-50(for='sub_district') แขวง (ตำบล)
          select#sub_district.form-select.form-select-lg(v-model="address.sub_district" required)
            option(value="" disabled) เลือกแขวง (ตำบล)
            option(v-for='sub_district in sub_districts',
          :value='sub_district.id',
          :key='sub_district.id',
        ) {{ sub_district.name_th }}
          .invalid-feedback กรุณาเลือกแขวง (ตำบล)

        .form-group
          label.form-label.fs-14.mb-3.opacity-50(for="zipcode") รหัสไปรษณีย์
          select#zipcode.form-select.form-select-lg(
            v-model='address.zipcode',
            required,
            ref='zipcode'
          )
            option(value="" disabled) เลือกรหัสไปรษณีย์
            option(v-for='zipcode in zipcodes',
              :value='zipcode',
              :key='zipcode'
            ) {{ zipcode }}
          .invalid-feedback กรุณาเลือกรหัสไปรษณีย์

        .form-group
          label.form-label.fs-5.fw-bold.mb-3.d-block(for="answer1") 1. เรียงลำดับบริการโดนใจของ <br>"Line GHB Buddy” จากมากไปน้อย 6 ลำดับ ดังนี้ 
          .d-grid.gap-3
            label#answer1.d-grid.gap-2.w-100(
              v-for="(choice, index) in choices_1",
              :key="choice.label",
              :for="`choices-${choice.label}`"
            )
              .choice-item.d-flex.align-items-center.border.rounded-3.py-2.px-3
                input.form-check-input.d-none(
                  v-model="answer1",
                  type="checkbox",
                  :value="choice.value",
                  :id="`choices-${choice.label}`"
                  name="choice_1"
                  required
                ) 
                span.me-3.flex-shrink-0(v-if="answer1.includes(choice.value)") อันดับ {{answer1.indexOf(choice.value)+1}}
                icon-check.me-3.flex-shrink-0(v-else)
                span.w-100.d-block.lh-sm.py-2(v-html="choice.label")

          .invalid-feedback(
            :class="{ 'd-block': !(answer1.length===6) && submited }"
          ) กรุณาเรียงลำดับ 6 ลำดับ

        .form-group
          label.form-label.fs-5.fw-bold.mb-3(for="answer2") 2. จากข้อที่ 1 ทำไมคุณถึงชอบบริการที่คุณให้ลำดับ 1 มากที่สุด
          textarea#answer2.form-control.form-control-lg(
            v-model="form.answer2",
            rows="3",
            required
            name="answer2"
          )
          .invalid-feedback กรุณากรอก
        

        .form-group
          label.form-label.fs-5.fw-bold.mb-3.d-block(for="answer3") ปัจจุบันคุณใช้บริการอะไรของธอส.
          .d-grid.gap-3
            label#answer3.d-grid.gap-2.w-100(
              v-for="(choice, index) in choices_2",
              :key="choice.label",
              :for="`choices-${choice.label}`"
            )
              .choice-item.d-flex.align-items-center.border.rounded-3.py-2.px-3
                input.form-check-input.d-none(
                  v-model="form.answer3",
                  type="radio",
                  :value="choice.value",
                  :id="`choices-${choice.label}`"
                  name="choice_1"
                  required
                ) 
                icon-check.me-3.flex-shrink-0
                span.w-100.d-block.lh-sm.py-2(v-html="choice.label")
            .invalid-feedback(
              :class="{ 'd-block': !form.answer3 && submited }"
            ) กรุณาเลือก 

        p.text-center.opacity-50
          strong หมายเหตุ 
          span : กรุณากรอกข้อมูลให้ถูกต้องครบถ้วน <br> เพื่อประโยชน์ในการร่วมกิจกรรมของท่าน

        .alert.alert-danger(ref="alert" v-if="errorMessages" v-html="errorMessages")

    .step.step-success.d-flex.align-items-center(v-if="step === 3")
      .w-100.fs-14
        .text-center.my-2
          happy-student.w-50.h-auto.mx-auto
        p.text-primary.fw-bold.mb-4 ธอส. ขอขอบพระคุณสำหรับการเข้าร่ววมสนุกกับกิจกรรม “บัดดี้รู้ใจ ส่งความสุขทุกเรื่องบ้าน เกมเรียงลำดับบริการโดนใจของ Line GHB Buddy 6 ลำดับ” ประจำเดือนมีนาคม 2566 ระยะเวลากิจกรรมวันที่ 25 - 27 มีนาคม 2566 เวลา 23.59 น. และจะประกาศผลรางวัลวันที่ 31 มีนาคม 2566 เวลา 19.00 น. ที่ LINE OA “GHB Buddy”
        p.fw-bold ของรางวัลประจำเดือนมีนาคม 2566 มีดังนี้ 
        ol
          li หุ่นยนต์ดูดฝุ่น TEFAL มูลค่ากว่า 8,500 บาท จำนวน 1 รางวัล
          li พริวิเลจการ์ด จาก PTT Station มูลค่า 500 บาท จำนวน 30 รางวัล
        p.fw-bold ขั้นตอนในการรับของรางวัล
        ol
          li ท่านสามารถตรวจสอบรายชื่อผู้โชคดีได้ที่ LINE VOOM ของ GHB Buddy
          li กดลิงก์ที่อยู่ในโพสต์ประกาศรางวัลประจำเดือนของ LINE VOOM เพื่อยืนยันสิทธิ์การเป็นผู้โชคดีภายในระยะเวลาที่กำหนด หากท่านไม่ยืนยันสิทธิ์การเป็นผู้โชคดีตามระยะเวลาที่กำหนด ทางธนาคารจะถือว่าท่านสละสิทธิ์ทันที
        p เงื่อนไข และหลักเกณฑ์เป็นไปตามที่ธนาคารกำหนด และคำตัดสินของคณะกรรมการถือเป็นที่สิ้นสุด<br>*ข้อมูล ณ วันที่ 25 มีนาคม 2566


    .app-action.position-fixed.start-0.end-0.bottom-0.p-3
      template(v-if="step === 1")
        .pb-2.text-center.fs-14(for="acceptTerm") ข้าพเจ้ายอมรับในข้อตกลงเเละเงื่อนไข
        .d-flex.gap-2

          button.btn.btn-lg.btn-secondary.rounded-pill.w-50(
            :disabled="!hasScrolledToBottom || isLoading"
            :class="{ 'opacity-50' : !hasScrolledToBottom }"
            @click="success"
          ) ไม่ยอมรับ

          button.btn.btn-lg.btn-block.rounded-pill.text-white.w-50.btn-primary(
            type="button",
            :disabled="!hasScrolledToBottom || isLoading",
            @click="acceptTerm",
            :class="{ 'opacity-50' : !hasScrolledToBottom }"
          ) 
            span.spinner-border.spinner-border-sm(
              v-if="isLoading",
              role="status",
              aria-hidden="true"
            )
            span(v-else) ยอมรับ
      template(v-if="step === 2")
        button.btn.btn-primary.btn-lg.btn-block.rounded-pill.text-white.w-100(
          type="submit",
          :disabled="isLoading || !form.accept_term",
          @click="onSubmit"
        ) 
          span.spinner-border.spinner-border-sm(
            v-if="isLoading",
            role="status",
            aria-hidden="true"
          )
          span(v-if="check") กรุณาเลือกคำตอบให้ครบ
          span(v-else) ส่งคำตอบ

      template(v-if="step === 3 || step === 4")
        button.btn.btn-primary.btn-lg.btn-block.rounded-pill.text-white.w-100(
          type="button",
          @click="success"
        )
          span(v-if="step === 4") ปิดหน้าต่าง
          span(v-else) เสร็จสิ้น
</template>

<script>
import axios from "axios";
import "../assets/bootstrap.scss";
import province from "@/data/province_with_amphure_tambon.json";
import liff from "@line/liff";
import Terms from "@/components/Terms.vue";
import HappyStudent from "@/components/HappyStudent.vue";
import IconCheck from "@/components/Check.vue";
export default {
  components: { Terms, HappyStudent, IconCheck },
  data() {
    return {
      province,
      districts: [],
      sub_districts: [],
      zipcodes: [],
      address: { province: "", district: "", sub_district: "", zipcode: "" },
      answer1: [],
      form: {
        accept_term: false,
        answer1: "",
        answer2: "",
        answer3: "",
      },
      check: false,
      step: 1,
      profile: {},
      isLoading: false,
      submited: false,
      errorMessages: "",
      hasScrolledToBottom: false,
      intersectionOptions: {
        root: null,
        rootMargin: "0px 0px 0px 0px",
        threshold: [0, 1],
      },
      choices_1: [
        {
          label: "ฟรี! ไม่มีค่าธรรมเนียม",
          value: "ฟรี! ไม่มีค่าธรรมเนียม",
        },
        {
          label: "เตือนทุกรายการเข้า-ออกบัญชีเงินฝาก",
          value: "เตือนทุกรายการเข้า-ออกบัญชีเงินฝาก",
        },
        {
          label: "เตือนทุกรายการชำระเงินกู้<br>ค่าเบี้ยประกันอัคคีภัย",
          value: "เตือนทุกรายการชำระเงินกู้ ค่าเบี้ยประกันอัคคีภัย",
        },
        {
          label: "แจ้งทันทีเมื่อถูกรางวัลสลากออมทรัพย์",
          value: "แจ้งทันทีเมื่อถูกรางวัลสลากออมทรัพย์",
        },
        {
          label: "แจ้งผลการขอสินเชื่อ",
          value: "แจ้งผลการขอสินเชื่อ",
        },
        {
          label: "แจ้งยอดชำระเงินกู้ล่วงหน้า",
          value: "แจ้งยอดชำระเงินกู้ล่วงหน้า",
        },
      ],
      choices_2: [
        {
          label: "สินเชื่อ",
          value: "สินเชื่อ",
        },
        {
          label: "เงินฝาก",
          value: "เงินฝาก",
        },
        {
          label: "สลากออมทรัพย์ธอส.",
          value: "สลากออมทรัพย์ธอส.",
        },
        {
          label: "ไม่เคยใช้บริการของธอส.",
          value: "ไม่เคยใช้บริการของธอส.",
        },
      ],
      // Production
      // liff: {
      //   a: "1655692268-oVAzbQ2b",
      //   b: "1655692268-myLWJj2J",
      //   c: "1655692268-QEb62A32",
      // },
      //
      // UAT
      // liff: {
      //   a: "1655803101-2OPDldbj",
      //   b: "1655803101-qZ3O7z5y",
      //   c: "1655803101-zMWrplLm",
      // },
    };
  },
  watch: {
    step() {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);
    },
    "address.province"(val) {
      if (!val) return;
      this.onProvinceChange();
    },
    "address.district"(val) {
      if (!val) return;
      this.onDistrictChange();
    },
    "address.sub_district"(val) {
      if (!val) return;
      this.onSubDistrictChange();
    },
  },

  mounted() {
    this.liffInit();
    window.addEventListener("scroll", this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    async liffInit() {
      // Production
      await liff.init({ liffId: "1655692268-oVAzbQ2b" });

      // UAT
      // await liff.init({ liffId: "1655803101-2OPDldbj" });

      // 3 Liff
      // await liff.init({ liffId: this.liff[this.$route.name] });

      if (liff.isLoggedIn()) {
        const isFriend = await this.getFriend();
        if (!isFriend) {
          alert("กรุณาเพิ่มเพื่อน GHB Buddy");
          window.location = "https://line.me/R/ti/p/@ghbbuddy";
        } else {
          await this.getProfile();
        }
      } else {
        liff.login();
      }
    },

    async getFriend() {
      const friend = await liff.getFriendship();
      return friend.friendFlag;
    },

    async getProfile() {
      const profile = await liff.getProfile();
      this.profile = profile;
    },

    onSubmit() {
      this.submited = true;
      this.errorMessages = "";
      const form = document.querySelector("form");
      if (!form.checkValidity()) {
        form.classList.add("was-validated");
        const el = form.querySelector("*:invalid");
        if (el) el.scrollIntoView();
        this.check = true;
        setTimeout(() => {
          this.check = false;
        }, 3000);
      } else {
        this.submit();
      }
    },

    async submit() {
      this.isLoading = true;
      this.form.user_id = this.profile.userId;
      // this.form.user_id = "test3";
      this.form.accept_term = this.form.accept_term ? "ยอมรับ" : "ไม่ยอมรับ";
      this.form.answer1 = this.answer1.toString();
      // this.step = 2;
      // console.log(this.form);
      try {
        await axios.post(
          `https://ghbank.digitalsetup.co/api/v1/campaign/25032023`,
          this.form
        );
        this.step = 3;
      } catch (error) {
        this.errorMessages = error.response.data.message;
        setTimeout(() => {
          this.$refs.alert.scrollIntoView();
        }, 100);
      } finally {
        this.isLoading = false;
      }
    },

    success() {
      liff.closeWindow();
    },

    onWaypoint({ going }) {
      if (going === this.$waypointMap.GOING_IN) this.hasScrolledToBottom = true;
    },

    acceptTerm() {
      this.step = 2;
      this.form.accept_term = 1;
    },

    onScroll() {
      if (document.body.getBoundingClientRect().top < -68) {
        document.body.classList.add("header-sticky");
      } else {
        document.body.classList.remove("header-sticky");
      }
    },

    onProvinceChange() {
      this.districts = this.province.find(
        (item) => item.id === this.address.province
      ).amphure;
      this.form.province = this.province.find(
        (item) => item.id === this.address.province
      ).name_th;
    },

    onDistrictChange() {
      this.sub_districts = this.districts.find(
        (item) => item.id === this.address.district
      ).tambon;
      this.form.district = this.districts.find(
        (item) => item.id === this.address.district
      ).name_th;
    },

    onSubDistrictChange() {
      this.zipcodes = [
        this.sub_districts.find((item) => item.id === this.address.sub_district)
          .zip_code,
      ];
      this.form.subdistrict = this.sub_districts.find(
        (item) => item.id === this.address.sub_district
      ).name_th;
      this.form.zipcode = this.zipcodes[0];
    },
  },
};
</script>

<style lang="scss">
body {
  max-width: 1024px;
  margin: auto;
  @media (min-width: 1025px) {
    background: #f8f8f8;
  }
}
.app {
  padding-bottom: 80px;
  background: #fff;
  min-height: 100vh;
  &[data-step="1"] {
    padding-bottom: 115px;
  }
  &[data-step="3"] {
    background: url(../assets/images/bg.jpg);
    background-size: cover;
  }
  &-header {
    height: 4.25rem;
    background-color: #ff850c;
    background-image: url(../assets/images/header-bg.jpg);
    background-size: auto 4.25rem;
    background-position: top right;
    background-repeat: no-repeat;
  }
  &-logo {
    height: 2.375rem;
    &-69 {
      height: 2.75rem;
    }
  }
  &-action {
    background-color: rgba(255, 255, 255, 0.6);
    backdrop-filter: saturate(200%) blur(20px);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    @media (min-width: 1025px) {
      width: 1024px;
      left: 50% !important;
      transform: translateX(-50%);
    }
  }
}
.choice-item {
  &:active {
    opacity: 0.5;
  }
  input:checked {
    user-select: none;
  }
  input:checked + svg {
    fill: var(--bs-primary);
  }
  input:disabled + svg {
    opacity: 0.3;
    & + span {
      opacity: 0.3;
    }
  }
}
.step-success {
  min-height: calc(100vh - 120px);
}
.mx-n12 {
  margin-left: -12px;
  margin-right: -12px;
}
</style>
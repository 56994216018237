import Vue from "vue";
import App from "./App.vue";
import reward from "./page/Reward.vue"
import Index from "./page/Index.vue"
import VueGtm from "@gtm-support/vue2-gtm";
import VueWaypoint from "vue-waypoint";
import VueRouter from "vue-router/dist/vue-router.min.js";

Vue.config.productionTip = false;

// Waypoint plugin
Vue.use(VueWaypoint);
Vue.use(VueRouter);
Vue.use(VueGtm, {
  id: "GTM-N7FW4DX",
});

const router = new VueRouter({
  mode: "history",
  routes: [
    { path: "/", name: "", component: Index },
    { path: "/a", name: "a", component: Index },
    { path: "/b", name: "b", component: Index },
    { path: "/c", name: "c", component: Index },
    { path: "/reward", name: "reward", component: reward },
  ],
});

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");